::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: rgb(33, 37, 41);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 225, 100, 0.959);
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 193, 7, 0.911);
  border-radius: 12px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 193, 7, 0.911) rgb(33, 37, 41);
}